import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ContractsForm from "./ContractsForm";
import "moment-timezone";
import "moment/locale/es";
import { useNavigate } from "react-router-dom";
import { fetchWrapper } from "../services/api";
import ContractHistoryComponent from "./ContractHistory";
import ContractsTableMobile from "./ContractsTableMobile";
import LoaderOverlay from "./loaderOverlay";
import FilterChips from "./filters";
import Row from "./RowPrincipal";
import DialogReason from "./dialog/dialogReason";
import moment from "moment-timezone";

function ContractsTable({
  socketRef,
  lastUpdate,
  setLastUpdate,
  currentUser,
  onShowToast,
  onClearToast,
}) {
  const [uploading, setUploading] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [anchorElPdf, setAnchorElPdf] = React.useState(null);
  const [editingContractId, setEditingContractId] = useState(null);
  const [openReasonsModal, setOpenReasonsModal] = useState(false);
  const [action, setAction] = useState("");
  const [selectedPhase, setSelectedPhase] = useState("");
  const [phases, setPhases] = useState([]);
  const [contractId, setContractId] = React.useState(null);
  const [contractsWithSupplementaryFiles, setContractsWithSupplementaryFiles] =
    useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [openLoader, setOpenLoader] = useState(false);
  const rolesAddNewContract = [
    "Deportivo",
    "Administrativo",
    "Deportivo Femenil",
    "Fuerzas Basicas Varonil",
    "Deportivo Femenil Sub 19",
    "Legal",
  ];

  const userRoles = localStorage.getItem("roles");

  const handleGetContractsSupplementary = async () => {
    fetchWrapper
      .get("/contracts/contracts_with_supplementary_files/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        maxBodyLength: Infinity,
      })
      .then((response) => {
        setContractsWithSupplementaryFiles(
          JSON.stringify(response.data.contract_ids)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOpenReasonsModal = (contractId, action) => {
    setContractId(contractId);
    setAction(action);
    setOpenReasonsModal(true);
  };

  const handleCloseReasonsModal = () => {
    setContractId(null);
    setOpenReasonsModal(false);
  };

  const handleOpenPdfMenu = (event, rowId) => {
    setAnchorElPdf(event.currentTarget);
    document.getElementById(`file-upload[${rowId}]`).click();
  };

  const handleClosePdfMenu = (selectedSetting) => {
    // if (selectedSetting === 'Adjuntar PDF') {
    // }
    // setAnchorElPdf(null);
  };

  // Modal
  const handleOpenModal = (contractId) => {
    setEditingContractId(contractId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSignatureButtonClick = (contractId) => {
    openSignatureWindow(contractId, token);
  };

  const openSignatureWindow = (contractId, token) => {
    console.log("TABLE CONTRACT -> ", contractId);
    console.log("TABLE TOKEN -> ", token);
    navigate({
      pathname: "/signature",
      state: { contractId: contractId, token: token },
    });
  };

  const handleFileChange = async (event, rowID) => {
    setOpenLoader(true);
    const file = event.target.files[0];
    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("contract_id", rowID);
    setTimeout(async () => {
      fetchWrapper
        .post("/contracts/upload_file/", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
        .then((response) => {
          setOpenLoader(false);
          handleAccept(rowID, null);
          if (socketRef.current) {
            socketRef.current.emit("message", "Archivo subido con éxito.");
          }
        })
        .catch((error) => {
          console.error("Error al subir el archivo:", error);
        })
        .finally(() => {
          setUploading(false);
          setSelectedRowId(null);
        });
    }, 500);
  };

  const [rows, setRows] = useState([]);

  const contractsPerPhase = (token, phaseId = null) => {
    const url =
      phaseId == null
        ? `/contracts_per_phase`
        : `/contracts_per_phase?phase_id=${phaseId}`;

    fetchWrapper
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setRows(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos de la API:", error);
        onShowToast(
          "Error",
          "Ocurrio un error al traer los contratos.",
          "error"
        );
      });
  };

  const handleGetContractsPhase = async () => {
    fetchWrapper
      .get("/get_contract_phases", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPhases(response.data);
        setOpenLoader(false);
      })
      .catch((error) => {
        console.error("Error al obtener las fases:", error);
        onShowToast("Error", "Ocurrio un error al traer las fases.", "error");
      });
  };

  useEffect(() => {
    setOpenLoader(true);
    setToken(token);
    handleGetContractsSupplementary();
    handleGetContractsPhase();
    contractsPerPhase(token);

    setOpenLoader(false);
  }, [contractsWithSupplementaryFiles, lastUpdate]);

  const handleAccept = async (contractId, reason = null) => {
    setOpenLoader(true);
    fetchWrapper
      .put(`/contracts/${contractId}/accept_contract/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: { reason: reason },
      })
      .then(async (response) => {
        setOpenLoader(false);
        console.log("Contrato actualizado:", response.data);
        onShowToast("Correcto", "Contrato aceptado correctamente", "success");
        setLastUpdate(new Date().toISOString());
      })
      .catch((error) => {
        setOpenLoader(false);
        console.error("Error al aceptar el contrato:", error);
        onShowToast(
          "Error",
          "Ocurrio un error al aceptar el contrato. " +
            error.response.data.non_field_errors,
          "error"
        );
      });
  };

  const handleReject = (contractId, reason) => {
    setOpenLoader(true);
    fetchWrapper
      .put(`/contracts/${contractId}/reject_contract/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { reason: reason },
      })
      .then((response) => {
        setOpenLoader(false);
        console.log("Contrato Rechazado:", response.data);
        onShowToast(
          "Rechazado con exito",
          "Contrato rechazado correctamente",
          "success"
        );
        setLastUpdate(new Date().toISOString());
      })
      .catch((err) => {
        setOpenLoader(false);
        console.error("Error al rechazar el contrato:", err);
        onShowToast(
          "Error",
          "Ocurrio un error al rechazar el contrato.",
          "error"
        );
      });
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <div>
      <LoaderOverlay open={openLoader} />
      <div className="title">
        <h2>CONTRATOS</h2>
      </div>
      <section
        id="filters"
        style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}
      >
        <div style={{ marginLeft: "auto" }}>
          <Button
            color="secondary"
            variant="contained"
            style={{ color: "#fff" }}
            size="small"
            onClick={() => navigate("/my-requests")}
          >
            Mis Solicitudes
          </Button>
        </div>
        <div style={{ marginLeft: 5 }}>
          {userRoles &&
            rolesAddNewContract.some((role) => userRoles.includes(role)) && (
              <>
                <Button
                  onClick={handleOpenModal}
                  sx={{ color: "#fff" }}
                  size="small"
                  variant="contained"
                  endIcon={<AddIcon />}
                >
                  Agregar
                </Button>
                <ContractsForm
                  isOpen={openModal}
                  handleClose={handleCloseModal}
                  token={token}
                  contractInformation={null}
                  onShowToast={onShowToast}
                  onClearToast={onClearToast}
                  setLastUpdate={setLastUpdate}
                />
              </>
            )}
        </div>
      </section>
      <FilterChips setRows={setRows} />
      <DialogReason
        open={openReasonsModal}
        handleClose={handleCloseReasonsModal}
        contractId={contractId || ""}
        action={action}
        handleAccept={handleAccept}
        handleReject={handleReject}
      />

      {isMobile ? (
        <ContractsTableMobile
          rows={rows}
          handleFileChange={handleFileChange}
          socketRef={socketRef}
          anchorElPdf={anchorElPdf}
          handleClosePdfMenu={handleClosePdfMenu}
          currentUser={currentUser}
          handleOpenModal={handleOpenModal}
          openModal={openModal}
          editingContractId={editingContractId}
          handleCloseModal={handleCloseModal}
          token={token}
          handleOpenReasonsModal={handleOpenReasonsModal}
          openReasonsModal={openReasonsModal}
          handleCloseReasonsModal={handleCloseReasonsModal}
          action={action}
          handleAccept={handleAccept}
          handleReject={handleReject}
        />
      ) : (
        <TableContainer
          sx={{ maxHeight: 600 }}
          component={Paper}
          className="contracts-table"
        >
          <Table stickyHeader aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Solicitante
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Título de contrato
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Área
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Tipo de Contrato
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", maxWidth: 30 }}
                >
                  Justificación de Contrato
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Fase Actual
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Fecha de Creación
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Última Actualización
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row) => (
                  <Row
                    key={row.id}
                    name={row.id}
                    row={row}
                    handleCloseModal={handleCloseModal}
                    onShowToast={onShowToast}
                    handleOpenReasonsModal={handleOpenReasonsModal}
                    currentUser={currentUser}
                    editingContractId={editingContractId}
                    setLastUpdate={setLastUpdate}
                    openModal={openModal}
                    handleOpenModal={handleOpenModal}
                    socketRef={socketRef}
                    handleFileChange={handleFileChange}
                    contractsWithSupplementaryFiles={
                      contractsWithSupplementaryFiles
                    }
                    setOpenLoader={setOpenLoader}
                  />
                ))
              ) : (
                <TableRow sx={{ background: "#fffee8" }}>
                  <TableCell
                    colSpan={10}
                    style={{
                      textAlign: "center",
                      padding: "20px 0",
                      color: "#333333",
                    }}
                  >
                    No se encontraron registros.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default ContractsTable;
