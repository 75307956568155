import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  TableCell,
  Stack,
} from "@mui/material";
import PropTypes from "prop-types";
import { fetchWrapper } from "../../services/api";
import { columns } from "./config";
import LoaderOverlay from "../loaderOverlay";
import Row from "../Row";

const MyRequests = ({ onShowToast, onClearToast }) => {
  const defaultTheme = createTheme();
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [contracts, setContracts] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);
  const [contractSF, setContractSF] = useState([]);

  const getContracts = (newPage = page, newRowsPerPage = rowsPerPage) => {
    const url = `/contracts_per_phase?me=true&filter=1&page=${newPage}&limit=${newRowsPerPage}`;

    fetchWrapper
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setContracts(response.data);
        setOpenLoader(false);
      })
      .catch((error) => {
        onShowToast(
          "Error",
          "Ocurrio un error al traer los contratos.",
          "error"
        );
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleGetContractsSupplementary = () => {
    fetchWrapper
      .get("/contracts/contracts_with_supplementary_files/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        maxBodyLength: Infinity,
      })
      .then((response) => {
        setContractSF(response.data.contract_ids);
      })
      .catch((err) => {
        onShowToast(
          "Error",
          "Ocurrio un error al traer los contratos con archivos suplementarios.",
          "error"
        );
      });
  };

  useEffect(() => {
    getContracts(page, rowsPerPage);
  }, [page, rowsPerPage]); // Se vuelve a ejecutar cuando 'page' o 'rowsPerPage' cambian

  useEffect(() => {
    handleGetContractsSupplementary();
    setOpenLoader(true);
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <LoaderOverlay open={openLoader} setOpen={setOpenLoader} />
      <Stack
        spacing={{ xs: 1, sm: 3 }}
        direction="row"
        useFlexGap
        sx={{ flexWrap: "wrap" }}
      >
        <Typography variant="h5" textAlign={"center"}>
          Mis Solicitudes
        </Typography>
        <TableContainer
          sx={{
            maxHeight: 750,
            boxShadow:
              "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;",
          }}
          component={Paper}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {contracts.map((row) => (
                <Row
                  key={row.id}
                  row={row}
                  contractSF={contractSF}
                  onShowToast={onShowToast}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, "all"]}
            component="div"
            count={contracts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página"
          />
        </TableContainer>
      </Stack>
    </ThemeProvider>
  );
};

MyRequests.propTypes = {
  onShowToast: PropTypes.func.isRequired,
  onClearToast: PropTypes.func.isRequired,
};

export default MyRequests;
