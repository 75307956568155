import React, { useState, useEffect, useRef } from "react";
import TerminationForm from "./TerminationForm";
import TransferForm from "./TransferForm";
import EmploymentForm from "./EmploymentForm";
import RepresentationForm from "./RepresentationForm";
import ModificationForm from "./ModificationForm";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { fetchWrapper, urlBase } from "../services/api";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
// import AdministrationFileUploader from "./AdministrationFileUploader";
import { setDisableFormSubmit } from "../slices";
import { intoDataForm } from "../forms/editForm";
import { DropzoneArea } from "material-ui-dropzone";

const ContractsForm = ({
  isOpen,
  handleClose,
  token,
  contractInformation,
  onShowToast,
  setLastUpdate,
  editingContractId,
}) => {
  const [area, setArea] = useState("");
  const [contractType, setContractType] = useState("");
  const [contracts, setContracts] = useState([]);
  const [contractTitle, setContractTitle] = useState("");
  const [justification, setJustification] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const dispatch = useDispatch();
  const [selectedEditContractType, setSelectedEditContractType] = useState("");
  const { disableFormSubmit } = useSelector((state) => state.inputsForm);
  const roles = localStorage.getItem("roles");
  const [files, setFiles] = useState([]);

  const {
    fullName,
    category,
    requestDate,
    modificationsToMake,
    playerOrCoach,
  } = useSelector((state) => state.modificationForm);

  const { supplementaryFileType } = useSelector((state) => state.general);

  const {
    terminationTerminationType,
    terminationCategory,
    terminationRequestDate,
    terminationFullName,
    terminationTerminationAmount,
    terminationPaymentDate,
    terminationSigningDate,
    terminationNotes,
  } = useSelector((state) => state.terminationForm);

  const {
    transferClubName,
    transferClubRepresentative,
    transferEmail,
    transferTransferType,
    transferValidity,
    transferFreeOrCost,
    transferPurchaseOption,
    transferSolidarityWithholding,
    transferOtherConditions,
  } = useSelector((state) => state.transferForm);

  const {
    representationFullName,
    representationNationality,
    representationAddress,
    representationRfcOrTaxId,
    representationPassportNumber,
    representationFifaRegistrationNumber,
    representationConsideration,
    representationOtherConditions,
    representationBankDetails,
    representationJurisdiction,
  } = useSelector((state) => state.representationForm);

  const {
    employmentPosition,
    employmentContractType,
    employmentDirectedTo,
    employmentCategory,
    employmentRequestDate,
    employmentFullName,
    employmentNationality,
    employmentDateOfBirth,
    employmentAddress,
    employmentMaritalStatus,
    employmentEmail,
    employmentFreeAgentOrTransfer,
    employmentRelocation,
    employmentContractDuration,
    employmentAnnualSalary,
    employmentFirstPaymentDate,
    employmentLastPaymentDate,
    employmentPaymentFrequency,
    employmentSportsBonuses,
    employmentOtherBenefits,
    employmentPlayerTerminationClause,
    employmentClubTerminationClause,
    employmentJurisdiction,
    employmentSigningDate,
  } = useSelector((state) => state.employmentForm);

  const handleSelectedFile = (files) => {
    setFiles(files);
  };

  const handlerUploadFile = async (contractId) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("contract_id", contractId);
    formData.append("administration_file", "administration_file");

    fetchWrapper
      .post("/contracts/upload_file/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      })
      .then((response) => {
        onShowToast("Éxito", "Archivo subido exitosamente", "success");
        setLastUpdate(new Date().toISOString());
        setFiles([]);
      })
      .catch((error) => {
        console.error("Error al subir el archivo:", error);
        onShowToast("Error", "Error al subir el archivo", "error");
        setOpenLoader(false);
        setFiles([]);
        cleanDataForm();
      });
  };

  const searchDetails = () => {
    if (supplementaryFileType == "modification agreement") {
      return {
        supplementary_file_type: supplementaryFileType,
        agreement_type: playerOrCoach,
        category: category,
        request_date: prepareDateForDatabase(requestDate),
        full_name: fullName,
        modifications_to_make: modificationsToMake,
      };
    }

    if (supplementaryFileType == "termination") {
      return {
        supplementary_file_type: supplementaryFileType,
        termination_type: terminationTerminationType,
        termination_amount: terminationTerminationAmount,
        signing_date: prepareDateForDatabase(terminationSigningDate),
        request_date: prepareDateForDatabase(terminationRequestDate),
        payment_date: prepareDateForDatabase(terminationPaymentDate),
        notes: terminationNotes,
        full_name: terminationFullName,
        category: terminationCategory,
      };
    }

    if (supplementaryFileType == "transfer contract") {
      return {
        supplementary_file_type: supplementaryFileType,
        club_name: transferClubName,
        club_representative: transferClubRepresentative,
        email: transferEmail,
        transfer_type: transferTransferType,
        validity: prepareDateForDatabase(transferValidity),
        free_or_cost: transferFreeOrCost,
        purchase_option: transferPurchaseOption,
        solidarity_withholding: transferSolidarityWithholding,
        other_conditions: transferOtherConditions,
      };
    }

    if (supplementaryFileType == "representation contract") {
      return {
        supplementary_file_type: supplementaryFileType,
        full_name: representationFullName,
        nationality: representationNationality,
        address: representationAddress,
        rfc_or_tax_id: representationRfcOrTaxId,
        passport_number: representationPassportNumber,
        fifa_registration_number: representationFifaRegistrationNumber,
        consideration: representationConsideration,
        other_conditions: representationOtherConditions,
        bank_details: representationBankDetails,
        jurisdiction: representationJurisdiction,
      };
    }

    if (supplementaryFileType == "employment contract") {
      return {
        supplementary_file_type: supplementaryFileType,
        position: employmentPosition,
        contract_type: employmentContractType,
        directed_to: employmentDirectedTo,
        category: employmentCategory,
        request_date: prepareDateForDatabase(employmentRequestDate),
        full_name: employmentFullName,
        nationality: employmentNationality,
        date_of_birth: prepareDateForDatabase(employmentDateOfBirth),
        address: employmentAddress,
        marital_status: employmentMaritalStatus,
        email: employmentEmail,
        free_agent_or_transfer: employmentFreeAgentOrTransfer,
        relocation: employmentRelocation,
        contract_duration: prepareDateForDatabase(employmentContractDuration),
        annual_salary: employmentAnnualSalary,
        first_payment_date: prepareDateForDatabase(employmentFirstPaymentDate),
        last_payment_date: prepareDateForDatabase(employmentLastPaymentDate),
        payment_frequency: employmentPaymentFrequency,
        sports_bonuses: employmentSportsBonuses,
        other_benefits: employmentOtherBenefits,
        player_termination_clause: employmentPlayerTerminationClause,
        club_termination_clause: employmentClubTerminationClause,
        jurisdiction: employmentJurisdiction,
        signing_date: prepareDateForDatabase(employmentSigningDate),
      };
    }
  };

  const formik = useFormik({
    initialValues: {
      area: area,
      contractType: "",
      contractTitle: "",
      justification: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.contractType) {
        errors.contractType = "Debe seleccionar un tipo de contrato";
      }
      if (!values.contractTitle) {
        errors.contractTitle = "Debe ingresar un título de contrato";
      }
      if (!values.justification) {
        errors.justification = "Debe ingresar una justificación";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      const formData = {
        applicant: localStorage.getItem("currentUser"),
        area: area,
        type: formik.values.contractType,
        contract_title: formik.values.contractTitle,
        request_reason: formik.values.justification,
      };

      if (buttonText === "Editar") {
        if (
          contractInformation.phase_description == "Rechazado" &&
          formik.values.contractType != 11
        ) {
          formData["_type"] = "normal";
        } else if (
          contractInformation.phase_description == "Solicitado" &&
          formik.values.contractType == 11
        ) {
          formData["_type"] = "administration";
        } else {
          formData["_type"] = false;
        }

        axios
          .put(
            `${urlBase}/contracts/${contractInformation.id}/update_contract/`,
            formData,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then(async (response) => {
            await updateContractType(
              selectedEditContractType,
              contractInformation.id
            );
            onShowToast("Éxito", "Contrato editado exitosamente", "success");
            handleCloseSub();
            setLastUpdate(new Date().toISOString());
          })
          .catch((error) => {
            setSubmitting(false);
            console.error("Error al editar el contrato:", error);
            onShowToast("Error", "Error al editar el contrato", "error");
          });

        return;
      }

      if (buttonText === "Solicitar") {
        if (formik.values.contractType == 11 && files.length === 0) {
          onShowToast("Error", "Debes seleccionar un archivo", "error");
          setSubmitting(false);
          return;
        }

        formData["details"] = searchDetails();
        axios
          .post(`${urlBase}/contracts/`, formData, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then(async (response) => {
            if (formik.values.contractType == 11) {
              await handlerUploadFile(response.data.id);
              setSubmitting(false);
              return;
            }

            // if (supplementaryFileType == "modification agreement") {
            //   createModificationAgreement(response.data.id);
            // }

            // if (supplementaryFileType == "termination") {
            //   createTermination(response.data.id);
            // }

            // if (supplementaryFileType == "transfer contract") {
            //   createTransferContract(response.data.id);
            // }

            // if (supplementaryFileType == "representation contract") {
            //   createRepresentationContract(response.data.id);
            // }

            // if (supplementaryFileType == "employment contract") {
            //   createEmploymentContract(response.data.id);
            // }

            onShowToast("Éxito", "Contrato solicitado exitosamente", "success");
            setLastUpdate(new Date().toISOString());
            setSubmitting(false);
            formik.resetForm();
          })
          .catch((error) => {
            setSubmitting(false);
            console.error("Error al solicitar el contrato:", error);
            onShowToast("Error", error.response.data.message, "error");
            formik.resetForm();
          });
      }

      handleCloseSub();
      formik.resetForm();
    },
  });

  const updateContractType = async (type, contract_id) => {
    if (type == "background" || formik.values.contractType == 11) {
      setLastUpdate(new Date().toISOString());
      return;
    }
    const data = getDataUpdateType(type);
    fetchWrapper
      .put(`/contracts/${contract_id}/update_contract_type/?type=${type}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
      .then((response) => {
        onShowToast(
          "Éxito",
          "Tipo de contrato actualizado correctamente",
          "success"
        );
        setLastUpdate(new Date().toISOString());
      })
      .catch((error) => {
        console.error("Error al actualizar el tipo de contrato", error);
        onShowToast(
          "Error",
          "Error al actualizar el tipo de contrato" + error,
          "error"
        );
      });
  };

  const getTerminationForm = () => {
    return {
      termination_type: terminationTerminationType,
      category: terminationCategory,
      request_date: prepareDateForDatabase(terminationRequestDate),
      full_name: terminationFullName,
      termination_amount: terminationTerminationAmount,
      payment_date: prepareDateForDatabase(terminationPaymentDate),
      signing_date: prepareDateForDatabase(terminationSigningDate),
      notes: terminationNotes,
    };
  };

  const getModificationAgreement = () => {
    return {
      agreement_type: playerOrCoach,
      category: category,
      request_date: prepareDateForDatabase(requestDate),
      full_name: fullName,
      modifications_to_make: modificationsToMake,
    };
  };

  const getTransferContract = () => {
    return {
      club_name: transferClubName,
      club_representative: transferClubRepresentative,
      email: transferEmail,
      transfer_type: transferTransferType,
      validity: prepareDateForDatabase(transferValidity),
      free_or_cost: transferFreeOrCost,
      purchase_option: transferPurchaseOption,
      solidarity_withholding: transferSolidarityWithholding,
      other_conditions: transferOtherConditions,
    };
  };

  const getRepresentationContract = () => {
    return {
      full_name: representationFullName,
      nationality: representationNationality,
      address: representationAddress,
      rfc_or_tax_id: representationRfcOrTaxId,
      passport_number: representationPassportNumber,
      fifa_registration_number: representationFifaRegistrationNumber,
      consideration: representationConsideration,
      other_conditions: representationOtherConditions,
      bank_details: representationBankDetails,
      jurisdiction: representationJurisdiction,
    };
  };

  const getEmploymentContract = () => {
    return {
      position: employmentPosition,
      contract_type: employmentContractType,
      directed_to: employmentDirectedTo,
      category: employmentCategory,
      request_date: prepareDateForDatabase(employmentRequestDate),
      full_name: employmentFullName,
      nationality: employmentNationality,
      date_of_birth: prepareDateForDatabase(employmentDateOfBirth),
      address: employmentAddress,
      marital_status: employmentMaritalStatus,
      email: employmentEmail,
      free_agent_or_transfer: employmentFreeAgentOrTransfer,
      relocation: employmentRelocation,
      contract_duration: prepareDateForDatabase(employmentContractDuration),
      annual_salary: employmentAnnualSalary,
      first_payment_date: prepareDateForDatabase(employmentFirstPaymentDate),
      last_payment_date: prepareDateForDatabase(employmentLastPaymentDate),
      payment_frequency: employmentPaymentFrequency,
      sports_bonuses: employmentSportsBonuses,
      other_benefits: employmentOtherBenefits,
      player_termination_clause: employmentPlayerTerminationClause,
      club_termination_clause: employmentClubTerminationClause,
      jurisdiction: employmentJurisdiction,
      signing_date: prepareDateForDatabase(employmentSigningDate),
    };
  };

  const getDataUpdateType = (type) => {
    const typesContracts = {
      transfer: getTransferContract,
      employment: getEmploymentContract,
      representation: getRepresentationContract,
      modification: getModificationAgreement,
      termination: getTerminationForm,
    };

    return typesContracts[type]();
  };

  useEffect(() => {
    if (formik.values.contractType != 2) {
      dispatch(setDisableFormSubmit(false));
    } else {
      dispatch(setDisableFormSubmit(true));
    }
  }, [formik.values.contractType]);

  useEffect(() => {
    const fetchData = async () => {
      if (isOpen) {
        setOpenLoader(true);
        if (contractInformation) {
          setContractTitle(contractInformation.contract_title);
          setJustification(contractInformation.request_reason);
          setArea(contractInformation.area);
          setButtonText("Editar");
        } else {
          setButtonText("Solicitar");
          setArea(typeof roles === "string" ? roles : roles[0]);
          formik.values.area = typeof roles === "string" ? roles : roles[0];
        }

        try {
          const response = await axios.get(`${urlBase}/get_contract_types`, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          setContracts(response.data);

          if (contractInformation) {
            prepareDataEdit(response.data);
          }

          setOpenLoader(false);
        } catch (error) {
          setOpenLoader(false);
          console.error("Error fetching contract types:", error);
          onShowToast(
            "Error",
            "Error al cargar los tipos de contrato",
            "error"
          );
        }
      }
    };

    fetchData();
  }, [isOpen, token, contractInformation]);

  const prepareDataEdit = (data) => {
    const selectedContract = data.find(
      (contract) =>
        contract.description === contractInformation.type_description
    );

    const typesContracts = {
      1: "transfer",
      2: "employment",
      3: "representation",
      4: "background",
      5: "modification",
      6: "termination",
      7: "termination",
    };

    const typeContract = typesContracts[selectedContract.id];

    fetchWrapper
      .get(
        `/contracts/${contractInformation.id}/get_by_type/?type=${typeContract}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSelectedEditContractType(typeContract);
        intoDataForm(response.data, typeContract, dispatch);
      })
      .catch((error) => {
        console.log(error);
      });
    formik.values.contractType = selectedContract.id;
    formik.values.contractTitle = contractInformation.contract_title;
    formik.values.justification = contractInformation.request_reason;
    setOpenLoader(false);
  };

  const prepareDateForDatabase = (dateToFormat) => {
    let date = new Date(dateToFormat);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const createTermination = (contractId) => {
    const formData = {
      supplementary_file_type: supplementaryFileType,
      termination_type: terminationTerminationType,
      termination_amount: terminationTerminationAmount,
      signing_date: prepareDateForDatabase(terminationSigningDate),
      request_date: prepareDateForDatabase(terminationRequestDate),
      payment_date: prepareDateForDatabase(terminationPaymentDate),
      notes: terminationNotes,
      full_name: terminationFullName,
      contract: contractId,
      category: terminationCategory,
    };

    axios
      .post(`${urlBase}/forms/`, formData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setOpenLoader(false);
        onShowToast(
          "Exito",
          "Contrato de terminación creado exitosamente",
          "success"
        );
        setLastUpdate(new Date().toISOString());
      })
      .catch((err) => {
        setOpenLoader(false);
        console.log(
          "Ocurrio un error el intentar anexar el contrato de terminación: ",
          err
        );
      });
  };

  const createModificationAgreement = (contractId) => {
    const formData = {
      supplementary_file_type: supplementaryFileType,
      agreement_type: playerOrCoach,
      category: category,
      request_date: prepareDateForDatabase(requestDate),
      full_name: fullName,
      modifications_to_make: modificationsToMake,
      contract: contractId,
    };

    axios
      .post(`${urlBase}/forms/`, formData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setOpenLoader(false);
        onShowToast(
          "Exito",
          "Contrato de modificación creado exitosamente",
          "success"
        );
        setLastUpdate(new Date().toISOString());
      })
      .catch((err) => {
        setOpenLoader(false);
        console.log(
          "Ocurrio un error el intentar anexar el convenio modificatorio: ",
          err
        );
      });
  };

  const createTransferContract = (contractId) => {
    const formData = {
      supplementary_file_type: supplementaryFileType,
      club_name: transferClubName,
      club_representative: transferClubRepresentative,
      email: transferEmail,
      transfer_type: transferTransferType,
      validity: prepareDateForDatabase(transferValidity),
      free_or_cost: transferFreeOrCost,
      purchase_option: transferPurchaseOption,
      solidarity_withholding: transferSolidarityWithholding,
      other_conditions: transferOtherConditions,
      contract: contractId,
    };

    axios
      .post(`${urlBase}/forms/`, formData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setOpenLoader(false);
        onShowToast(
          "Exito",
          "Contrato de transferencia creado exitosamente",
          "success"
        );
        setLastUpdate(new Date().toISOString());
      })
      .catch((err) => {
        setOpenLoader(false);
        console.log(
          "Ocurrio un error el intentar anexar el contrato de transferencia: ",
          err
        );
      });
  };

  const createRepresentationContract = (contractId) => {
    const formData = {
      supplementary_file_type: supplementaryFileType,
      full_name: representationFullName,
      nationality: representationNationality,
      address: representationAddress,
      rfc_or_tax_id: representationRfcOrTaxId,
      passport_number: representationPassportNumber,
      fifa_registration_number: representationFifaRegistrationNumber,
      consideration: representationConsideration,
      other_conditions: representationOtherConditions,
      bank_details: representationBankDetails,
      jurisdiction: representationJurisdiction,
      contract: contractId,
    };

    axios
      .post(`${urlBase}/forms/`, formData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setOpenLoader(false);
        onShowToast(
          "Exito",
          "Contrato de representación creado exitosamente",
          "success"
        );
        setLastUpdate(new Date().toISOString());
      })
      .catch((err) => {
        setOpenLoader(false);
        console.log(
          "Ocurrio un error el intentar anexar el contrato de representación: ",
          err
        );
      });
  };

  const createEmploymentContract = (contractId) => {
    const formData = {
      supplementary_file_type: supplementaryFileType,
      position: employmentPosition,
      contract_type: employmentContractType,
      directed_to: employmentDirectedTo,
      category: employmentCategory,
      request_date: prepareDateForDatabase(employmentRequestDate),
      full_name: employmentFullName,
      nationality: employmentNationality,
      date_of_birth: prepareDateForDatabase(employmentDateOfBirth),
      address: employmentAddress,
      marital_status: employmentMaritalStatus,
      email: employmentEmail,
      free_agent_or_transfer: employmentFreeAgentOrTransfer,
      relocation: employmentRelocation,
      contract_duration: prepareDateForDatabase(employmentContractDuration),
      annual_salary: employmentAnnualSalary,
      first_payment_date: prepareDateForDatabase(employmentFirstPaymentDate),
      last_payment_date: prepareDateForDatabase(employmentLastPaymentDate),
      payment_frequency: employmentPaymentFrequency,
      sports_bonuses: employmentSportsBonuses,
      other_benefits: employmentOtherBenefits,
      player_termination_clause: employmentPlayerTerminationClause,
      club_termination_clause: employmentClubTerminationClause,
      jurisdiction: employmentJurisdiction,
      signing_date: prepareDateForDatabase(employmentSigningDate),
      contract: contractId,
    };

    setTimeout(() => {
      axios
        .post(`${urlBase}/forms/`, formData, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          setOpenLoader(false);
          onShowToast(
            "Exito",
            "Contrato de trabajo creado exitosamente",
            "success"
          );
          setLastUpdate(new Date().toISOString());
        })
        .catch((err) => {
          setOpenLoader(false);
          console.log(
            "Ocurrio un error el intentar anexar el contrato de trabajo: ",
            err
          );
        });
    }, 500);
  };

  const handleModalClick = (event) => {
    if (event.target === event.currentTarget) {
      cleanDataForm();
      handleClose();
    }
  };

  const handleCloseSub = () => {
    cleanDataForm();
    formik.resetForm();
    handleClose();
  };

  const cleanDataForm = () => {
    setContractTitle("");
    setJustification("");
    setContractType("");
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseSub}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
          onClick={handleModalClick}
        >
          <Backdrop
            open={openLoader || formik.isSubmitting}
            style={{ zIndex: 1300001 }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div
            style={{
              height: "60vh",
              background: "#fff",
              padding: "2rem",
              width: 1000,
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
            }}
          >
            <div style={{ textAlign: "center", marginBottom: "15px" }}>
              <Typography variant="h6">Formulario de Contrato</Typography>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                label="Solicitante"
                variant="outlined"
                disabled
                value={localStorage.getItem("currentUser")}
                style={{ marginBottom: "1rem", width: "100%" }}
              />
              <div style={{ marginBottom: "1rem" }}>
                <TextField
                  label="Área"
                  variant="outlined"
                  disabled
                  value={formik.values.area}
                  onChange={formik.handleChange}
                  name="area"
                  style={{ width: "100%" }}
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel>Tipo de Contrato</InputLabel>
                  <Select
                    value={formik.values.contractType}
                    onChange={formik.handleChange}
                    name="contractType"
                    label="Tipo de Contrato"
                  >
                    {contracts.map((contract) => (
                      <MenuItem key={contract.id} value={contract.id}>
                        {contract.description}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.errors.contractType && (
                    <div style={{ color: "red" }}>
                      {formik.errors.contractType}
                    </div>
                  )}
                </FormControl>
              </div>
              <TextField
                label="Título de Contrato"
                variant="outlined"
                value={formik.values.contractTitle}
                onChange={formik.handleChange}
                name="contractTitle"
                inputProps={{ maxLength: 70 }}
                style={{ marginBottom: "1rem", width: "100%" }}
              />
              {formik.errors.contractTitle && (
                <div style={{ color: "red" }}>
                  {formik.errors.contractTitle}
                </div>
              )}
              <TextareaAutosize
                aria-label="Justificación de Contrato"
                placeholder="Justificación de Contrato"
                multiline="true"
                minRows={7}
                value={formik.values.justification}
                onChange={formik.handleChange}
                name="justification"
                maxLength={1500}
                style={{ width: "100%", marginBottom: "1rem" }}
              />
              {formik.errors.justification && (
                <div style={{ color: "red" }}>
                  {formik.errors.justification}
                </div>
              )}

              {formik.values.contractType == 1 ? <TransferForm /> : null}

              {formik.values.contractType == 2 ? <EmploymentForm /> : null}

              {formik.values.contractType == 3 ? <RepresentationForm /> : null}

              {formik.values.contractType == 5 ? <ModificationForm /> : null}

              {formik.values.contractType == 6 ||
              formik.values.contractType == 7 ? (
                <TerminationForm />
              ) : null}

              {formik.values.contractType == 11 && buttonText != "Editar" && (
                <DropzoneArea
                  acceptedFiles={["application/pdf", "application/msword"]}
                  onChange={handleSelectedFile}
                  showFileNames
                  dropzoneText="Arraste el archivo aqui o clique para selecionar"
                  showAlerts={false}
                  filesLimit={1}
                  maxFileSize={30000000} // 30MB
                />
              )}
              <div id="actions" style={{ textAlign: "right" }}>
                {editingContractId ? (
                  <Button
                    id="edit-button"
                    variant="outlined"
                    color="primary"
                    type="submit"
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    Editar
                  </Button>
                ) : (
                  <Button
                    id="request-button"
                    variant="outlined"
                    color="primary"
                    type="submit"
                    disabled={disableFormSubmit}
                  >
                    Solicitar
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ContractsForm;
